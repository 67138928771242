import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {GrowingRoutes} from "../../growing/growing-routing.module";
import {GaiaIconSet} from "../../models/gaia-icon-set";
import {environment} from "../../../environments/environment";
import {SentryErrorHandler} from "../../services/sentry-error-handler";
import {Router} from "@angular/router";

@Component({
  selector: 'tasks-menu',
  templateUrl: './tasks-menu.component.html',
  styleUrls: ['./tasks-menu.component.scss']
})
export class TasksMenuComponent implements OnInit {

  gaiaIcons = GaiaIconSet;

  constructor(
    private router: Router
  ) {
  }
  ngOnInit(): void {
    if (!this.isProduction()) {
      // alert("hello");
      this.router.navigate(['/']);
    }
  }

  protected readonly GrowingRoutes = GrowingRoutes;

  isProduction() {
    return environment.production;
  }
}
