import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SessionDataService} from '../../services/session-data.service';
import {InternalNoteTrackingService} from '../../internal-notes/internal-note-tracking.service';
import {GaiaRecord} from '../../models/gaia-record.model';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {LayoutTrackingService} from '../layout-tracking.service';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-screen-layout',
  templateUrl: './screen-layout.component.html',
  styleUrls: ['./screen-layout.component.scss']
})
export class ScreenLayoutComponent implements OnInit {

  isMobile = false;
  showMenu = true;

  showToolBar = true;
  trackerShowMenu = true;
  fullScreenMode = false;

  hideSideColumn = false;
  collapseSideColumn = false;

  gaiaIconSet = GaiaIconSet;

  constructor(
    private sessionData: SessionDataService,
    private internalNotesTrackingService: InternalNoteTrackingService,
    private screenLayoutTracking: LayoutTrackingService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ){
  }

  ngOnInit(): void {
    this.internalNotesTrackingService.currentRecord.subscribe((response) => {
      this.updateScreenLayout(response);
    });
    this.screenLayoutTracking.isHandset$.subscribe((value) => {
      this.isMobile = value;
      this.updateScreenLayout();
    });
    this.screenLayoutTracking.showTopMenu.subscribe((value) => this.showToolBar = value);
    this.screenLayoutTracking.showSideMenu.subscribe((value) => {
      this.trackerShowMenu = value;
      this.updateScreenLayout();
    });
    this.screenLayoutTracking.fullscreenMode.subscribe((value) => this.fullScreenMode = value);
  }

  updateScreenLayout(trackedRecord?: GaiaRecord){
    if (!this.isMobile){
      this.showMenu = this.trackerShowMenu;
    } else {
      this.showMenu = false;
    }
    // If on mobile or there is no trackedRecord then we hide side column to save on screen space
    this.hideSideColumn = this.isMobile || !trackedRecord;

    // // Check if the current URL contains the specific path
    // const currentUrl = this.router.url;
    // if (currentUrl.includes('/growing/growers/')) {
    //   this.hideSideColumn = true; // Hide the side column if the URL matches
    // }
    this.changeDetectorRef.detectChanges();
  }

  closeSideMenu() {
    this.collapseSideColumn = !this.collapseSideColumn;
  }

  menuOpen(event: boolean) {
    this.showMenu = event;
  }
}
