import { Component } from '@angular/core';

@Component({
  selector: 'app-tasks-title-popup',
  templateUrl: './tasks-title-popup.component.html',
  styleUrls: ['./tasks-title-popup.component.scss']
})
export class TasksTitlePopupComponent {

}
