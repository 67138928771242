import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GrowersModule} from './growers/growers.module';
import {ContractedFieldsModule} from './contracted-fields/contracted-fields.module';
import {DroneImagesModule} from './drone-images/drone-images.module';
import {GrowerContractsModule} from './grower-contracts/grower-contracts.module';
import {MiscanthusVarietiesModule} from './miscanthus-varieties/miscanthus-varieties.module';
import {SoilSamplesModule} from './soil-samples/soil-samples.module';
import { GrowingMenuComponent } from './growing-menu/growing-menu.component';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {PlantingOpportunitiesModule} from './planting-opportunities/planting-opportunities.module';
import {ContractsModule} from './contracts/contracts.module';
import {AssetsManagementModule} from "../assets-management/assets-management.module";
import {TasksManagementModule} from "../tasks-management/tasks-management.module";

@NgModule({
  declarations: [
    GrowingMenuComponent
  ],
  exports: [
    GrowingMenuComponent,
  ],
    imports: [
        CommonModule,
        GrowersModule,
        ContractedFieldsModule,
        DroneImagesModule,
        GrowerContractsModule,
        MiscanthusVarietiesModule,
        SoilSamplesModule,
        PlantingOpportunitiesModule,
        MatIconModule,
        MatMenuModule,
        RouterModule,
        MatButtonModule,
        ContractsModule,
        AssetsManagementModule,
        TasksManagementModule
    ]
})
export class GrowingModule { }
