

<app-universal-search-preview-wrapper [searchResult]="record" classType="Contact" [icon]="gaiaIconSet.contacts">

  <mat-list>
    <mat-list-item>
      <span matLine>Name</span>
      <span matLine>{{record.first_name}} {{ record.surname}}</span>
    </mat-list-item>
    <mat-list-item>
      <span matLine>Phone</span>
      <span matLine>{{record.phone}}</span>
    </mat-list-item>
  </mat-list>

  <ng-container buttons>
    <button mat-icon-button>
      <mat-icon (click)="showContact()">edit</mat-icon>
    </button>
  </ng-container>

  <ng-container links>
    <mat-list *ngIf="previewData">
      <mat-list-item>
        <mat-icon matListIcon>{{ gaiaIconSet.contacts }}</mat-icon>
        <div matLine>
          <span i18n>Accounts</span> ({{ previewData.accounts_count }})
        </div>
      </mat-list-item>
      <mat-list-item>
        <div *ngIf="previewData.accounts?.length">
          <mat-list>
            <mat-list-item *ngFor="let account of previewData.accounts">
<!--              <mat-icon matListIcon>account_circle</mat-icon>-->
              <div matLine *ngIf="account.grower">
                <span> Grower:
<!--      <mat-icon (click)="showContact()">edit</mat-icon>-->
                  <a (click)="navigateGrower(account.grower.id)" class="clickable-link">
                    {{ account.grower.grower_code }}
                  </a>
                </span>
              </div>
              <div matLine *ngIf="account.haulier">
                <span> Haulier:
                  <a (click)="navigateHaulier(account.haulier.id)" class="clickable-link">
                    {{ account.haulier.id }}
                  </a>
                </span>
              </div>
              <div matLine *ngIf="account.haulier_driver">
                <span> Haulier Driver: {{ account.haulier_driver.id }}</span>
              </div>
              <div matLine *ngIf="account.contractor">
                <span> Contractor:
                  <a (click)="navigateContractor(account.contractor.id)" class="clickable-link">
                    {{ account.contractor.id }}
                  </a>
                </span>
              </div>
              <div matLine *ngIf="account.stockist">
                <span> Stockist: {{ account.stockist.id }}</span>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
      </mat-list-item>
    </mat-list>
  </ng-container>

</app-universal-search-preview-wrapper>

