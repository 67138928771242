import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../services/rest-api.service';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Task} from "./task.model";

@Injectable({
  providedIn: 'root'
})
export class TaskManagementService extends RestApiService<Task> {

  constructor(httpClient: HttpClient) {
    super('', '', httpClient);
  }

  saveAsset(asset: any) {
    return this.httpClient.post(environment.server_url + `/grower_tasks`, asset, { withCredentials: true })
  }

  updateTask(id: any, task: any) {
    return this.httpClient.put(environment.server_url + `/grower_tasks/${id}`, task, { withCredentials: true })
  }

  findTask(id: any): Observable<Task>{
    return this.httpClient.get<Task>(environment.server_url + `/grower_tasks/` + id, { withCredentials: true })
  }

  loadTaskDependencies(): Observable<any>{
    return this.httpClient.get<any>(environment.server_url + `/grower_tasks/task_dependencies`, { withCredentials: true })
  }

  loadAllTasks(filterParams?: any, growerCode?: any): Observable<Task[]>{
    return this.httpClient.get<Task[]>(environment.server_url + `/grower_tasks?filter_params=${filterParams}&grower_code=${growerCode}`, { withCredentials: true })
      .pipe(map((response: Task[]) => {
        console.log("loadAllTasks", response)
        // return response["company_assets"];
        return response;
      }));
  }

  completeTask(id: any) {
    return this.httpClient.post(environment.server_url + `/grower_tasks/complete_task?task_id=${id}`, id, { withCredentials: true })
  }
}
