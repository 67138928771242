import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TasksMenuComponent} from "./tasks-menu/tasks-menu.component";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {RouterModule} from "@angular/router";
import {TasksTableComponent} from "./tasks-table/tasks-table.component";
import {TasksPopupComponent} from "./tasks-popup/tasks-popup.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {AssetsManagementModule} from "../assets-management/assets-management.module";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {TasksTitleTableComponent} from "./tasks-titles/tasks-title-table/tasks-title-table.component";
import {TasksTitlePopupComponent} from "./tasks-titles/tasks-title-popup/tasks-title-popup.component";
import {FullCalendarModule} from "@fullcalendar/angular";
// Import necessary FullCalendar plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

@NgModule({
  declarations: [
    TasksMenuComponent,
    TasksTableComponent,
    TasksPopupComponent,
    TasksTitleTableComponent,
    TasksTitlePopupComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    AssetsManagementModule,
    MatOptionModule,
    MatSelectModule,
    FullCalendarModule
  ],
  exports: [
    TasksMenuComponent,
    TasksTableComponent,
    TasksPopupComponent,
    TasksTitleTableComponent,
    TasksTitlePopupComponent
  ]
})
export class TasksManagementModule { }
