import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UniversalSearchToolComponent} from './universal-search-tool/universal-search-tool.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {ContactsModule} from '../contacts/contacts.module';
import {GrowersModule} from '../growing/growers/growers.module';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import { UniversalSearchPopupComponent } from './universal-search-popup/universal-search-popup.component';
import {MatButtonModule} from '@angular/material/button';
import {HauliersModule} from '../operations/hauliers/hauliers.module';
import {ProcessorsModule} from '../operations/processors/processors.module';
import {ContractorsModule} from '../operations/contractors/contractors.module';
import {BaleCollectionsModule} from '../operations/bale-collections/bale-collections.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ContractedFieldsModule} from '../growing/contracted-fields/contracted-fields.module';
import {AssetsManagementModule} from "../assets-management/assets-management.module";
import {TasksManagementModule} from "../tasks-management/tasks-management.module";

@NgModule({
  declarations: [UniversalSearchToolComponent, UniversalSearchPopupComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        ContactsModule,
        GrowersModule,
        MatCardModule,
        RouterModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        HauliersModule,
        ProcessorsModule,
        ContractorsModule,
        BaleCollectionsModule,
        MatProgressSpinnerModule,
        ContractedFieldsModule,
        AssetsManagementModule,
        TasksManagementModule
    ],
  exports: [UniversalSearchToolComponent]
})
export class UniversalSearchModule { }
