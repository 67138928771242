import {Component, Inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {TaskManagementService} from "../task-management.service";
import {LoadingService} from "../../loading/loading.service";

@Component({
  selector: 'tasks-popup',
  templateUrl: './tasks-popup.component.html',
  styleUrls: ['./tasks-popup.component.scss']
})
export class TasksPopupComponent implements OnInit{
  grower_code: string;
  taskForm: FormGroup;
  task_titles: any[] = [];
  task_users: any[] = [];
  actionBtn: string = "Save"
  editData: any;

  constructor(
    private dialog: MatDialog,
    private builder: FormBuilder,
    private taskService: TaskManagementService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private loadingService: LoadingService
  ) {
    this.grower_code = data.grower_code;
    console.log("This is gets from tasks-popup-component", data)
    console.log("This is gets from tasks-popup-component", this.grower_code)
  }

  ngOnInit(): void {
    // alert("I am on ngOnInit");
    console.log("This is gets from tasks-popup-component", this.grower_code)

    this.taskForm = this.builder.group({
      id: this.builder.control({value: '', disabled: true}),
      title: this.builder.control('', Validators.required),
      grower_code: [this.grower_code],
      start_date: this.builder.control(''),
      task_created_by: this.builder.control(''),
      task_owner: this.builder.control(''),
      task_description: this.builder.control(''),
      notes: this.builder.control(''),
      due_date: this.builder.control(''),
      completed: this.builder.control('')
    })

    const taskDependencies$ =  this.taskService.loadTaskDependencies();
    this.loadingService.showLoaderUntilCompleted(taskDependencies$).subscribe({
      next:(res) => {
        this.task_titles = res["task_titles"];
        this.task_users = res["task_users"];
      },
      error: () => {
        alert("Error while fetching asset categories")
      }
    })

    if(this.data.id != '' && this.data.id != null) {
      // alert(this.data.id);
      this.actionBtn = "Update";

      const findTask$ = this.taskService.findTask(this.data.id)

      this.loadingService.showLoaderUntilCompleted(findTask$).subscribe(res => {
        this.editData = res;
        this.taskForm.setValue({
          id: this.editData.id,
          title: this.editData.title_id,
          grower_code:  this.editData.grower_code,
          start_date: this.editData.start_date,
          task_created_by: this.editData.task_created_by,
          task_owner: this.editData.task_owner,
          task_description: this.editData.description,
          notes: this.editData.notes,
          due_date: this.editData.due_date,
          completed: this.editData.completed
        })
      })
    }

  }

  closePopup() {
    this.dialog.closeAll();
  }

  saveTask() {
    if(this.taskForm.valid) {
      const editId = this.taskForm.getRawValue().id
      if (editId != '' && editId != null) {
        this.taskService.updateTask(editId, this.taskForm.value).subscribe({
          next:() => {
            alert("Asset updated successfully");
            this.closePopup();
          },
          error:(res) => {
            console.log(res.error.error_message);
            alert(res.error.error_message);
          }
        })
      } else {
        this.taskService.saveAsset(this.taskForm.value).subscribe({
          next:() => {
            alert("Task created successfully");
            this.closePopup();
          },
          error:(res) => {
            console.log(res.error.error_message);
            alert(res.error.error_message);
            // alert("Error while creating the Task");
          }
        })
      }
    }
  }
}
