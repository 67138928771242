<ng-container [formGroup]="form" *ngIf="form">
  <div fxLayout="row" fxLayoutGap="15px">
    <mat-card fxFlex="calc(50% - 15px)">
      <mat-card-header>
        <mat-icon mat-card-avatar>{{ record?.grower_code ? gaiaIconSet.growers : gaiaIconSet.leads }}</mat-icon>
        <mat-card-title i18n>Grower Details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>ID: <span>{{ record?.id }}</span></p>
        <mat-form-field>
          <mat-label i18n>Grower Code</mat-label>
          <input matInput formControlName="grower_code" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Account Name</mat-label>
          <input matInput formControlName="account_name" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Vat Number</mat-label>
          <input matInput formControlName="vat_number" />
        </mat-form-field>

<!--        <mat-form-field>-->
<!--          <mat-label i18n>Farm Type1</mat-label>-->
<!--          <input matInput formControlName="farm_type" />-->
<!--        </mat-form-field>-->

        <mat-form-field>
          <mat-label i18n>Farm Status</mat-label>
          <mat-select placeholder="Select Farm Status"
                      formControlName="farm_status"
          >
            <mat-option value="contract_expired"> Contract Expired </mat-option>
            <mat-option value="contract_terminated"> Contract Terminated </mat-option>
            <mat-option value="on_contract"> On Contract </mat-option>
            <mat-option value="own_use"> Own Use </mat-option>
            <mat-option value="spot_ccntract"> Spot Contract </mat-option>
            <mat-option value="europen_contract"> European Contract </mat-option>
          </mat-select>


        </mat-form-field>


      </mat-card-content>
    </mat-card>

    <gaia-shared-address-embed-form fxFlex="calc(50% - 15px)" formGroupName="billing_address"></gaia-shared-address-embed-form>
  </div>
</ng-container>

<!--==================================================================-->
<!--Tasks starts from here-->
<!--==================================================================-->

<span *ngIf="!taskShow()">

<!--Filter and create new btn start-->
<div class="top_area filter_area">
  <div class="left_filter_area">
    <div class="task_icon">
      <mat-icon> list_alt</mat-icon>
    </div>
    <h4>Tasks : </h4>
  </div>

  <div class="right_filter_area">
    <mat-form-field class="filter_by_text">
      <mat-label>Task Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>
    <button mat-fab extended  mat-raised-button color="primary" (click)="openPopup('')"  >
      <mat-icon>add</mat-icon>
    </button>
  </div>


</div>
<!--Filter and create new btn end-->



<div class="table-area">
  <table mat-table [dataSource]="finalData" matSort class="mat-elevation-z8 assets_table">
    <!--    title Column-->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
      <td mat-cell *matCellDef="let row">
         {{row.title}}
      </td>
    </ng-container>

    <!--    start_date Column-->
    <ng-container matColumnDef="start_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
      <td mat-cell *matCellDef="let row"> {{row.start_date}} </td>
    </ng-container>

    <!--    task_created_by Column-->
    <ng-container matColumnDef="task_created_by">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
      <td mat-cell *matCellDef="let row"> {{row.task_created_by}} </td>
    </ng-container>

    <!--    task_owner Column-->
    <ng-container matColumnDef="task_owner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Task Owner </th>
      <td mat-cell *matCellDef="let row"> {{row.task_owner}} </td>
    </ng-container>

    <!--    due_date Column-->
    <ng-container matColumnDef="due_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Due Date </th>
      <td mat-cell *matCellDef="let row"> {{row.due_date}} </td>
    </ng-container>

    <!--    completed Column-->
    <ng-container matColumnDef="completed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Completed </th>
      <td mat-cell *matCellDef="let row"> {{row.completed}} </td>
    </ng-container>

    <!--    actions Column-->
    <ng-container matColumnDef="actions">
      <th class="table_action_head" mat-header-cell *matHeaderCellDef> ACTIONS </th>
      <td mat-cell *matCellDef="let row">
        <div>
          <a mat-stroked-button (click)="editTask(row.id)">
            <mat-icon>edit</mat-icon>
          </a>
          <a mat-stroked-button (click)="completeTask(row.id)">
            Complete Task
          </a>
        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter {{ input.value }} </td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 100]"
                 [pageSize]="10"
                 showFirstLastButtons
                 aria-label="Select page">
  </mat-paginator>

</div>
</span>
