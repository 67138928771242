<div class="logo">
  <div class="logo-background">
    <img src="/assets/terraflames.png" alt="terravesta flames" />
  </div>
</div>

<div class="menu-options">
  <a mat-flat-button aria-hidden="false" aria-label="Dashboard icon" color="primary" routerLink="/">
    <mat-icon>{{ gaiaIcons.home }}</mat-icon>
  </a>

  <a mat-flat-button color="primary" [routerLink]="['/', appRoutes.DASHBOARDS, dashboardsRoute.OVERVIEW_MAP]">
    <mat-icon>{{ gaiaIcons.map }}</mat-icon>
  </a>

  <app-contacts-menu></app-contacts-menu>

  <app-growing-menu></app-growing-menu>

  <app-operations-menu></app-operations-menu>
  <assets-menu></assets-menu>

  <!--
  <app-commercial-sales-menu></app-commercial-sales-menu>
  -->

  <app-marketing-menu></app-marketing-menu>

  <app-system-resources-menu></app-system-resources-menu>

  <app-business-settings-menu></app-business-settings-menu>

  <app-team-space-admin-menu *ngIf="currentUser.has_hr_access"></app-team-space-admin-menu>

  <app-system-admin-menu *ngIf="currentUser.is_system_admin"></app-system-admin-menu>

  <tasks-menu></tasks-menu>
</div>
