import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Grower} from '../grower.model';
import {GrowersService} from '../growers.service';
import {ActivatedRoute} from '@angular/router';
import {GrowerFormControlService} from '../grower-form-control.service';
import {ShowPageDirective} from '../../../components/show-page.directive';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InternalNoteTrackingService} from '../../../internal-notes/internal-note-tracking.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-grower-overview',
  templateUrl: './grower-show.component.html',
  styleUrls: ['./grower-show.component.scss']
})
export class GrowerShowComponent extends ShowPageDirective<Grower> {
  grower: Grower;

  constructor(
    growersService: GrowersService,
    growerFormControl: GrowerFormControlService,
    route: ActivatedRoute,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    internalNotesTracker: InternalNoteTrackingService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(growersService, growerFormControl, route, snackBar, dialog, internalNotesTracker, changeDetectorRef);
  }

  recordGot(record: Grower) {
    this.grower = record;
    console.log("AT grower-show.component.ts", this.grower)
  }

}

