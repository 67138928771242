import {Component} from '@angular/core';
import {ContactAccount, ContactUniversalSearchPreview, ContactUniversalSearchResult} from '../contact.model';
import {UniversalSearchPreviewDirective} from '../../universal-search/universal-search-preview.directive';
import {ContactsService} from '../contacts.service';
import {AppRoutes} from '../../app-routing.module';
import {Router} from '@angular/router';
import {GrowingRoutes} from "../../growing/growing-routing.module";
import {MatDialogRef} from "@angular/material/dialog";
import {
  UniversalSearchPopupComponent
} from "../../universal-search/universal-search-popup/universal-search-popup.component";
import {OperationsRoutes} from "../../operations/operations-routing.module";

@Component({
  selector: 'app-contact-universal-search-result',
  templateUrl: './contact-universal-search-result.component.html',
  styleUrls: ['./contact-universal-search-result.component.scss']
})
export class ContactUniversalSearchResultComponent extends UniversalSearchPreviewDirective<ContactUniversalSearchResult, ContactUniversalSearchPreview>{

  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;

  constructor(
    private router: Router,
    contactsService: ContactsService,
    private matDialogRef: MatDialogRef<UniversalSearchPopupComponent>
  ) {
    super(contactsService);
  }

  showContact() {
    this.router.navigate(['/', AppRoutes.CONTACTS, this.record.id]).then(() => {
      this.requestClose.emit();
    });
  }

  navigateGrower(id: any) {
    this.router.navigate(['/', this.appRoutes.GROWING, this.growingRoutes.GROWERS, id]).then(() => {
      this.matDialogRef.close();
    });
  }

  navigateHaulier(id: any) {
    this.router.navigate(['/', AppRoutes.OPERATIONS, OperationsRoutes.HAULIERS, id]).then(() => {
      this.matDialogRef.close();
    });
  }

  navigateContractor(id: any) {
    this.router.navigate(['/', AppRoutes.OPERATIONS, OperationsRoutes.CONTRACTORS, id]).then(() => {
      this.matDialogRef.close();
    });
  }

  protected readonly GrowingRoutes = GrowingRoutes;
}
