import {Component, OnInit, ViewChild} from '@angular/core';
import {RecordFormDirective} from '../../../components/record-form.directive';
import {Grower} from '../grower.model';
import {MatDialog} from "@angular/material/dialog";
import {TasksPopupComponent} from "../../../tasks-management/tasks-popup/tasks-popup.component";
import {FormBuilder, Validators} from "@angular/forms";
import {Task} from "../../../tasks-management/task.model";
import {LoadingService} from "../../../loading/loading.service";
import {TaskManagementService} from "../../../tasks-management/task-management.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-grower-form',
  templateUrl: './grower-form.component.html',
  styleUrls: ['./grower-form.component.scss']
})

export class GrowerFormComponent extends RecordFormDirective<Grower> implements OnInit {
  @ViewChild(MatPaginator) _paginator!:MatPaginator;
  @ViewChild(MatSort) _sort!:MatSort;

  grower_code: string;
  taskData: Task[] = [];
  displayColumns :string[] = ["title", "start_date", "task_created_by", "task_owner", "due_date", "completed", "actions"]
  finalData: any;
  paginatorData: { [key: string]: any } = {};
  filterText: any;

  constructor(
    private dialog:MatDialog,
    private loadingService: LoadingService,
    private taskService: TaskManagementService
  ) {
    super();
  }

  ngOnInit(): void {
    this.grower_code = this.form.get('grower_code')?.value || '';

    this.loadTaskData('',this.grower_code);

    console.log("this is at grower-form.component.ts", this.record)
  }

  openPopup(id: any) {
    const _popup = this.dialog.open(TasksPopupComponent, {
      width: '800px',
      data: {
        grower_code: this.grower_code,
        id: id
      }
    })

    _popup.afterClosed().subscribe(res => {
      this.loadTaskData('', this.grower_code);
    })
  }

  loadTaskData(filterParams?: any, growerCode?: any) {
    const allTaskData$ = this.taskService.loadAllTasks(filterParams, growerCode)
    this.loadingService.showLoaderUntilCompleted(allTaskData$).subscribe({
      next:(res) => {
        this.taskData = res;
        console.log("this.taskData", res)
        this.finalData = new MatTableDataSource<Task>(this.taskData["grower_tasks"])
        this.finalData.paginator=this._paginator;
        this.finalData.sort=this._sort;
        this.paginatorData['total_count'] = this.taskData['paginator']['total_count=']
        this.paginatorData['per_page'] = this.taskData['paginator']['per_page=']
      },
      error: () => {
        alert("Error while fetching tasks")
      }
    })
  }

  editTask(id) {
    this.openPopup(id);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterText = filterValue;
    this.finalData.filter = filterValue.trim().toLowerCase();
  }

  completeTask(id: string) {
    // alert(id);
    // window.location.reload()

    const task$ = this.taskService.completeTask(id)
    this.loadingService.showLoaderUntilCompleted(task$).subscribe({
      next:(res) => {
        window.location.reload()
      },
      error: () => {
        alert("Error while completing task process")
      }
    })
  }

  taskShow() {
    return true;
  }
}

