import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CalendarOptions} from "@fullcalendar/core";

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';


@Component({
  selector: 'tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: ['./tasks-table.component.scss']
})
export class TasksTableComponent {
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],  // Pass the necessary plugins directly
    initialView: 'dayGridMonth',  // Use 'dayGridMonth' as the initial view
    editable: true,               // Enable event editing (drag and drop)
    selectable: true,             // Enable selecting date ranges
    dayMaxEvents: true,           // Show "more" link when too many events
    events: [                     // Sample events
      { title: 'Event 1', date: '2024-09-01' },
      { title: 'Event 2', date: '2024-09-02' }
    ],
    select: (info) => {
      alert('Selected ' + info.startStr + ' to ' + info.endStr);
    },
    eventClick: (info) => {
      alert('Event: ' + info.event.title);
    }
  };
}
