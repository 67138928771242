<div class="asset_heading">
  <h1> {{ actionBtn == "Update" ? "Update" : "New" }} Grower Task </h1>
</div>
<loading></loading>
<div class="task_form_field">
  <form class="task_form" [formGroup]="taskForm">

    <mat-form-field style="display:none;">
      <input matInput formControlName="grower_code" />
    </mat-form-field>

    <!-- Row 1 start -->
    <div class="task_form_row">
      <mat-form-field class="input-full-width" appearance="fill">
        <mat-label>ID</mat-label>
        <input formControlName="id" matInput>
      </mat-form-field>
    </div>
    <!-- Row 1 end -->
    <!-- Row 2 start -->
    <div class="task_form_row">
      <mat-form-field class="input-full-width">
        <mat-label>Task Title</mat-label>
        <mat-select placeholder="Select Task Title"
                    formControlName="title"
        >
          <mat-option *ngFor="let title of task_titles" [value]="title.id"> {{ title.title }}   </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field  class="input-full-width notes-textarea">
        <mat-label>Grower Code: </mat-label>
        <input formControlName="" matInput>
      </mat-form-field>


    </div>
    <!-- Row 2 end -->

    <!-- Row 3 start -->
    <div class="task_form_row">
      <mat-form-field>
        <label>Start Date</label>
        <input matInput [matDatepicker]="startDateDatepicker" #searchInput
               formControlName="start_date">
        <mat-datepicker-toggle matSuffix
                               [for]="startDateDatepicker">
        </mat-datepicker-toggle>
        <mat-datepicker #startDateDatepicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <label>Due Date</label>
        <input matInput [matDatepicker]="dueDateDatepicker" #searchInput
               formControlName="due_date">
        <mat-datepicker-toggle matSuffix
                               [for]="dueDateDatepicker">
        </mat-datepicker-toggle>
        <mat-datepicker #dueDateDatepicker></mat-datepicker>
      </mat-form-field>
    </div>
    <!-- Row 3 end -->

    <!-- Row 4 start -->
    <div class="task_form_row">
      <mat-form-field class="input-full-width">
        <mat-label>Task Created By</mat-label>
        <mat-select placeholder="Task Created By"
                    formControlName="task_created_by">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let user of task_users" [value]="user.id"> {{ user.name }}   </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input-full-width">
        <mat-label>Task Owner</mat-label>
        <mat-select placeholder="Task Created By"
                    formControlName="task_owner">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let user of task_users" [value]="user.id"> {{ user.name }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Row 4 end -->

    <!-- Row 5 start -->
    <div class="task_form_row">
      <mat-form-field  class="input-full-width notes-textarea">
        <mat-label>Description</mat-label>
        <textarea formControlName="task_description" matInput></textarea>
      </mat-form-field>
    </div>
    <!-- Row 5 end -->

    <!-- Row 6 start -->
    <div class="task_form_row">
      <mat-form-field  class="input-full-width notes-textarea">
        <mat-label>Notes</mat-label>
        <textarea formControlName="notes" matInput></textarea>
      </mat-form-field>
    </div>
    <!-- Row 6 end -->

    <div>
      <button mat-raised-button color="primary" (click)="saveTask()"> {{ actionBtn }} </button>
      <a mat-button (click)="closePopup()"> Cancel </a>
    </div>

    <div class="action_btn">
      <button mat-raised-button color="primary" >Save</button>
      <button mat-raised-button color="primary" >Complete Task</button>
    </div>
  </form>
</div>
