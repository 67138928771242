import { Component } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {TasksTitlePopupComponent} from "../tasks-title-popup/tasks-title-popup.component";

@Component({
  selector: 'app-tasks-title-table',
  templateUrl: './tasks-title-table.component.html',
  styleUrls: ['./tasks-title-table.component.scss']
})
export class TasksTitleTableComponent {
  constructor(
    private dialog: MatDialog,
  ) {  }
  openPopup() {
    const _popup = this.dialog.open(TasksTitlePopupComponent)
  }


}
